<template>
    <v-row
      dense
      class="pl-4 ma-0"
      :class="{bb: !last}"
      align="center"
    >
      <v-col class="grow" >
        <div class="title clickable" @click="registerClick(null, null)">
          {{ title }}
          <v-btn
            v-if="tournament.externalRegistrationUrl"
            fab small
            color="color3 color3Text--text"
            @click="registerClick(null, null)"
            class="float-right"
            >
            <v-icon>fas fa-clipboard-check</v-icon>
          </v-btn>
        </div>
        <div class="mt-n2" v-if="!division.acceptanceOnly">
          <router-link
            :to="{name: 'division-teams', params: {divisionId: division.id}}"
            @click.native="$event.stopImmediatePropagation()"
            v-if="view.admin || (division.showTeams && division.hydrated)"
            class="color3--text"
            style="font-size: small"
          >{{division.teamCount}}{{!hideSpots && division.maxTeams ? `/${division.maxTeams}` : null}} Registered {{division.participantType}}{{ division.teamCount > 1 ? 's' : '' }}</router-link>
          <router-link
            :to="{name: 'division-waitlist', params: {divisionId: division.id}}"
            @click.native="$event.stopImmediatePropagation()"
            v-if="view.admin || (division.showTeams && division.hydrated && division.waitlistTeams.length > 0)"
            class="color3--text ml-2"
            style="font-size: small"
          >{{division.waitlistTeams.length}} Waitlist {{division.participantType}}{{ division.teamCount > 1 ? 's' : '' }}</router-link>
        </div>
        <div class="mt-n2" v-else>
          <router-link
            :to="{name: 'division-teams', params: {divisionId: division.id}}"
            @click.native="$event.stopImmediatePropagation()"
            v-if="view.admin || (division.showTeams && division.hydrated && division.teamCount)"
            class="color3--text"
            style="font-size: small"
          >{{division.teamCount}}{{!hideSpots && division.maxTeams ? `/${division.maxTeams}` : null}} Accepted Teams</router-link>
          <router-link
            :to="{name: 'division-waitlist', params: {divisionId: division.id}}"
            @click.native="$event.stopImmediatePropagation()"
            v-if="view.admin || (division.showTeams && division.hydrated)"
            class="color3--text ml-2"
            style="font-size: small"
          >{{division.waitlistTeams.length}} {{division.teamCount ? 'Pending' : ''}} Teams</router-link>
        </div>
        <v-alert type="error" :value="division.disableRegistration" v-if="division.disableRegistration" class="mb-0" text>
          <b>Registration is closed</b>
        </v-alert>
        <v-alert type="error" :value="division.isFull" v-else-if="division.isFull && !division.acceptanceOnly" class="mb-0">
          <b v-if="division.maxTeams >= 0">FULL</b> - Currently only taking waitlist
        </v-alert>
        <v-list
          v-if="!tournament.externalRegistrationUrl && !division.disableRegistration"
          two-line
          :three-line="$vuetify.breakpoint.xsOnly && hasNames"
          dense :class="listClass" class="pt-0 mt-n2"
        >
          <v-list-item
            class="pa-0"
            v-for="(w, i) in division.windows"
            :key="w.id"
            :class="{bb: (i + 1) != division.windows.length}"
            @click="registerClick(division, w)"
          >
            <v-list-item-action style="min-width: 15px" class="mr-1">
              <v-icon :color="w.isCurrent ? 'color1' : 'white'">fas fa-caret-right</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="w.name" class="subheading">
                <b>{{w.name}}</b>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1">
                <span class='text--primary'>{{`${tournament.formatMoney(w.fee)}/${w.feeIsPerTeam && (division.numOfPlayers > 1 || division.numAllowedOnRoster > 1) ? 'per team' : 'per player'}`}}. </span>
                <template v-if="!w.neverOpen">
                  <br v-if="$vuetify.breakpoint.xsOnly">
                  {{w.closed ? `Closed ${w.endDisplay}` : w.future ? `${w.startDisplay} - ${w.endDisplay}` : `Open until ${w.endDisplay}`}}
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="w.isCurrent"
                fab small
                color="color3 color3Text--text"
                @click="registerClick(division, w)"
                >
                <v-icon>fas fa-clipboard-check</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['division', 'last', 'listClass'],
  computed: {
    ...mapGetters(['view', 'user', 'tournament', 'getDivisionJProp']),
    hasNames () {
      return !!this.division.windows.filter(f => f.name).length
    },
    title () {
      return (this.tournament.isTournament || this.division.isTournament) ? this.division.name : this.division._Name
    },
    wl () {
      return this.division.waitlistTeams
    },
    hideSpots () {
      return this.getDivisionJProp(this.division.id, 'hideSpots')
    }
  },
  methods: {
    registerClick (division, w) {
      this.$emit('register-click', { division: division, window: w && w.isCurrent ? w : null })
    }
  }
}
</script>

<style scoped>
  .bb {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .h {
    cursor: pointer;
  }
</style>
