<template>
  <v-card class="elevation-1">
    <v-toolbar color="color1 color1Text--text" dense>
      <v-toolbar-title>
        {{title}}<sup v-if="suffix">{{suffix}}</sup>
        <span v-if="suffix">Place</span>
        {{subtitle}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid class="pt-0">
      <template v-for="(team, i) in teams">
        <v-row
          v-if="avatars && team.players && team.players.length > 0 && !useLogos"
          :key="'avatars'+i"
        >
          <v-col class="text-center" :cols="team.players.length === 1 ? 12: 6" v-for="(player,i) in team.players" :key="'player'+i">
            <v-avatar class="color2" size="64px">
              <v-img :src="`${urlBase}/PlayerProfile/${player.playerProfileId}/profilePic`"></v-img>
            </v-avatar>
            <div>
              <player-link :player="player"></player-link>
            </div>
          </v-col>
        </v-row>
        <v-row dense :key="'name'+i" v-else>
          <v-col :cols="user && user.id === 1 ? 11 : 12" class="d-flex align-center">
            <v-img
              v-if="useLogos"
              :src="team.logo"
              height="36"
              max-width="48"
              max-height="48"
              contain
              class="mr-2"
            ></v-img>
            <div>
              <div v-if="useTeamName">
                <team-link :tournamentId="tournament.id" :team="team"></team-link>
              </div>
              <div v-if="avatars || division.numOfPlayers <= 2">
                <template v-for="(player, i) in team.players">
                  <span :key="'team' + team.id + 'player'+ i + 's'" v-if="i > 0"> / </span>
                  <player-link :player="player" :key="'team' + team.id + 'player'+ i"></player-link>
                </template>
              </div>
            </div>
          </v-col>
          <v-col cols="1" class="text-end" v-if="user && user.id === 1">
            <v-btn
              color="color3 color3Text--text"
              :to="`/tournament/${tournament.id}/team/${team.id}`"
              @click.stop="dialog = true"
              text icon x-small
            >
              <v-icon>fas fa-clipboard-list</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-row dense class="pa-0">
        <v-col cols="12" class="caption text-right py-0" v-for="p in points" :key="p">
          {{p}}
        </v-col>
      </v-row>
      <v-row dense v-if="bids.length">
        <v-col cols="12" class="py-0" v-for="(bid, i) in bids" :key="`bid-${bid.bid.teamId}-${i}`">
          <v-alert type="success" :value="true" dense text border="left" icon="fas fa-award" class="mb-0" v-if="!bid.bid.trickle">
            {{bid.team.firstNames | formatArray}} have won a bid to <router-link :to="{name: 'tournament-brochure', params: { tournamentId: bid.bid.tournamentId }}" class="success--text">{{bid.bid.tournament}}</router-link>
          </v-alert>
          <v-alert type="warning" :value="true" dense text border="left" icon="fas fa-faucet-drip" class="mb-0" v-if="bid.bid.trickle">
            {{bid.team.firstNames | formatArray}} previously earned a bid to <router-link :to="{name: 'tournament-brochure', params: { tournamentId: bid.bid.tournamentId }}" class="warning--text">{{bid.bid.tournament}}</router-link>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import PlayerLink from '@/components/Player/PlayerLink.vue'
import TeamLink from '@/components/Team/TeamLink.vue'
import { mapGetters } from 'vuex'
import { api } from '@/classes/_URL'

export default {
  props: ['finish', 'division'],
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    urlBase () {
      return api
    },
    title () {
      if (this.place === 1) return `Champion${this.division.numOfPlayers === 1 ? '' : 's'}`
      if (!this.place || this.place > 99999) return 'Participants'
      return this.place
    },
    subtitle () {
      return (this.useTeamName && this.avatars && this.finish.teams && this.finish.teams.length === 1) ? ` - ${this.finish.teams[0].name}` : null
    },
    useTeamName () {
      return this.division.numOfPlayers > 2 || this.division.isNcaa
    },
    useLogos () {
      return this.division && this.division.useLogos
    },
    suffix () {
      if (this.place !== this.title) return null
      const j = this.place % 10
      const k = this.place % 100
      if (j === 1 && k !== 11) return 'st'
      if (j === 2 && k !== 12) return 'nd'
      if (j === 3 && k !== 13) return 'rd'
      return 'th'
    },
    avatars () {
      return this.finish.place < 3
    },
    place () {
      return this.finish.place
    },
    teams () {
      return this.finish.teams
    },
    displayTeams () {
      return this.finish.teams.map(t => {
        return {
          team: t,
          bids: this.bids.filter(f => f.teamId === t.id)
        }
      })
    },
    points () {
      return this.teams[0].points.filter(f => f.total >= 1).map(m => `${m.short} pts: ${m.total}`)
    },
    bids () {
      if (!this.tournament || !this.teams) return []
      const ids = this.teams.map(m => m.id)
      const bids = this.tournament.bids && this.tournament.bids.filter(f => ids.includes(f.teamId))
      return bids ? bids.map(m => {
        return {
          bid: m,
          team: this.teams.find(f => f.id === m.teamId)
        }
      }) : []
    }
  },
  methods: {
  },
  components: {
    PlayerLink,
    TeamLink
  }
}
</script>

<style scoped>
.tLink {
  font-weight: 500;
}
.tLink:hover {
  text-decoration: underline;
}
</style>
